<template>
    <div id="my" class="vh-100">
        <!-- <header class="title_header hstack justify-content-between ps-2 pe-1">
            <button @click="$router.push('/')" class="btn btn-transparent position-relative">
                <i class="fal fa-chevron-left fs-px-20"></i>
            </button>
            <div class="position-relative">
                <button class="btn btn-transparent position-relative px-1 py-0" @click="$router.push('/alert')"><i class="fal fa-bell"></i><span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" v-if="alert_list.length||alert_list.length>0">{{ alert_list.length }} </span></button>
                <button class="btn btn-transparent" @click="$router.push('/my/setting')"><i class="fal fa-cog fs-px-20"></i></button>
            </div>
        </header> -->
        <TitleHeader :title="title" :link="'/home'" />

        <div class="scroll_content position-relative">
            <div class="top_info">
                <!-- <div class="position-relative">
                    <img :src="$store.state.my_info.avatar" alt="" class="ratio ratio-1x1 rounded-circle">
                    <span class="position-absolute rounded-pill bg-secondary camera_btn fa-lg" @click="editImg()"><i class="fas fa-camera small"></i></span>
                </div> -->
                <div class="flex-between-center w-100 pt-3 pb-2">
                    <!-- <span class="badge rounded-pill bg-blue mb-2 text">인증회원</span> -->
                    <div class="txt_box">
                        <h3 class="mb-1">{{nick_name}}</h3>
                        <router-link to="/my/edit" class="d-flex align-items-center fs-px-14">내 정보 관리 <i class="fal fa-chevron-right fa-xs ms-2"></i></router-link>
                    </div>
                    
                    <!-- <div class="d-flex flex-column text-center">
                        <router-link to="/my-assets/user" class="rounded-pill bg-main text-white p-2 px-3 mb-1">
                            MY 자산
                        </router-link>
                    </div> -->
                </div>
            </div>
            <div class="d-flex justify-content-around border py-3 px-3 mx-3 rounded my-3 pb-0">
                <div class="w-100">
                    <!-- <p class="mb-2">오늘까지 나의 <b>자산</b></p>
                    <div class="progress bg-gray-200 mb-3">
                        <div class="progress-bar progress-bar-striped bg-main" role="progressbar" style="width: 80%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div> -->
                    <ul class="point_list d-flex justify-content-around">
                        <li class="d-flex flex-column mb-3 w-100 border-end">
                            <small>현재 리워드 자산</small>
                            <h4 class="w-100 fw-bold text-truncate">
                                <span class="text-truncate">{{ total_point.toLocaleString() }}</span>
                                <small class="fw-light">p</small>
                            </h4>
                        </li>
                        <li class="d-flex flex-column mb-3 w-100 border-end">
                            <small>금일 리워드 지급</small>
                            <h4 class="w-100 fw-bold text-truncate">
                                <span class="text-truncate">{{ today_point.toLocaleString() }}</span>
                                <small class="fw-light">p</small>
                            </h4>
                        </li>
                        <li class="d-flex flex-column mb-3 w-100">
                            <small>금일 참여한 노래</small>
                            <h4 class="w-100 fw-bold text-truncate">
                                <span class="text-truncate">{{ my_play }}</span>
                                <small class="fw-light">개</small>
                            </h4>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="d-flex justify-content-around border py-3 px-2 mx-3 rounded shadow-sm">
                <ul class="my-list w-100 d-flex justify-content-around">
                    <li class="d-flex flex-column text-center">
                        <router-link to="/my-assets/user-list" class="rounded bg-main p-2 mb-1">
                            <img src="@/assets/img/icon/menu_1.png" alt="menu-1" height="25">
                        </router-link>
                        <small>노래내역</small>
                    </li>
                    <li class="d-flex flex-column text-center">
                        <router-link to="/my-assets/user" class="rounded bg-main p-2 mb-1">
                            <img src="@/assets/img/icon/menu_2.png" alt="menu-1" height="25">
                        </router-link>
                        <small>MY 자산</small>
                    </li>
                </ul>
            </div>
            <!-- <banner link="https://placehold.co/400x150?text=AD" class="mx-3 p-0 w-auto my-3"></banner> -->
            <h5 class="px-3 mt-5">고객센터</h5>
            <div class="d-flex justify-content-around mx-3 rounded mt-3">
                <ul class="w-100 fs-px-14">
                    <!-- <li>
                        <router-link to="/my/coupon-list" class="d-flex py-2 ps-2 justify-content-between align-items-center border-bottom">
                            <div><i class="fal fa-ticket me-3 text-center" style="width:25px"></i>쿠폰 구매/사용 내역</div>
                            <button class="btn btn-transparent"><i class="far fa-chevron-right"></i></button>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/my/edit" class="d-flex py-2 ps-2 justify-content-between align-items-center border-bottom">
                            <div><i class="fal fa-info me-3 text-center" style="width:25px"></i>내 정보</div>
                            <button class="btn btn-transparent"><i class="far fa-chevron-right"></i></button>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cs/event" class="d-flex py-2 ps-2 justify-content-between align-items-center border-bottom">
                            <div><i class="fal fa-calendar-star me-3 text-center" style="width:25px"></i>이벤트</div>
                            <button class="btn btn-transparent"><i class="far fa-chevron-right"></i></button>
                        </router-link>
                    </li> -->
                    <li>
                        <router-link to="/cs/notice" class="d-flex py-2 ps-2 justify-content-between align-items-center border-bottom">
                            <div>
                                <i class="fs-px-16 me-2 text-center fa-duotone fa-solid fa-bullhorn" style="width: 25px;"></i>
                                <!-- <i class="fs-px-16 fal fa-megaphone me-3 text-center" style="width:25px"></i> -->
                                공지사항
                            </div>
                            <button class="btn btn-transparent"><i class="far fa-chevron-right"></i></button>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cs/home" class="d-flex py-2 ps-2 justify-content-between align-items-center border-bottom">
                            <div>
                                <!-- <i class="fa-duotone fa-solid fa-warehouse-full"></i> -->
                                <i class="fs-px-16 fa-duotone fa-solid fa-headset me-3 text-center" style="width:25px"></i>고객센터</div>
                            <button class="btn btn-transparent"><i class="far fa-chevron-right"></i></button>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/my/setting" class="d-flex py-2 ps-2 justify-content-between align-items-center">
                            <div>
                                <!-- <i class="fa-duotone fa-solid fa-sliders"></i> -->
                                <i class="fs-px-18 fa-duotone fa-solid fa-sliders me-3 text-center" style="width:25px"></i>설정</div>
                            <button class="btn btn-transparent"><i class="far fa-chevron-right"></i></button>
                        </router-link>
                    </li>
<!--                 
                    <li>
                        <router-link to="#" class="d-flex p-3 ps-2 justify-content-between align-items-center">
                            <div><i class="fs-px-16 fal fa-rocket me-3 text-center" style="width:25px"></i>버전정보</div>
                            <div>v1.0.0</div>
                        </router-link>
                    </li> -->
                </ul>
            </div>
            <button class="btn btn-transparent text-gray-550 mt-5 fs-px-14" @click="Logout()">로그아웃 <i class="fal fa-sign-out me-1"></i></button>
            
        </div>
    </div>
</template>

<script>
const CryptoJS = require("crypto-js");
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title: '마이페이지',
            total_point:0,
            today_point:0,
            my_play : '',
            my_coupon:0,
            alert_list:this.$store.state.alert_list,
            nick_name :"",
            
            terms_list: [
                {
                    idx: 0,
                    title: "운영 정책",
                },
                {
                    idx: 1,
                    title: "이용자 약관 정보",
                },
                {
                    idx: 2,
                    title: "개인정보 처리 방침",
                },
                {
                    idx: 3,
                    title: "E-mail 및 SMS 광고성 정보",
                },
            ],

            show_terms: false,
        }
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
        this.GetProfile(); 
        this.$store.dispatch('CLOSETERM')
    },
    methods:{
        GetProfile(){

            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/mypage/GetProfile',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            
                            this.nick_name = body.nick;
                            this.total_point = body.total;
                            this.today_point = body.today;
                            this.my_play = body.song;
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(async () => {
                                window.location.href = '/signin';
                            });
                        }
                    }
                }
            );

        },
        editImg(){

        },
        Logout(){
            this.$store.dispatch('SETLOGOUT').then(async () => {
                window.location.href = '/signin';
            });
        }
    },
    
    destroyed(){
        
        this.$EventBus.$emit('ShowHeader')
    }
}
</script>

<style lang="scss" scoped>
#my{
    .title_header::before{
        content: "";
        display: block;
        width: 100%;
        height: 30%;
        min-height: 230px;
        max-height: 300px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
    }
    // background:linear-gradient(180deg, #2856a4 30%, #fff 30%);
    
    .scroll_content{
        height: calc(100vh - 60px);
        overflow-y: auto;
        padding-bottom: 15px;
    }
    .top_info{
        display: flex;
        align-items: center;
        padding: 0 1em;
        // height: calc(27% - 60px);
        // min-height: 120px;
        img{
            max-width: 150px;
            max-height: 150px;
        }
        // .txt_box{
        //     text-shadow: 0 2px 5px rgba(0,0,0,0.4);
        // }

        .camera_btn{
            bottom: 0;
            left: auto;
            right: 0;
            width: 35px;
            height: 35px;
            line-height: 35px;
            text-align: center;
        }
        // >div:last-child{
        //     width: 75%;
        //     .badge{
        //         width: fit-content;
        //         text-shadow: 0 0 7px black;
        //     }
        // }
    }
    
    .point_list>li{
        // width: 33.333%;
        text-align: center;
    }
    .my-list{
        a{
            padding: 1em !important;
            border-radius: 15px !important;
            text-align: center;
        }
    }
}

@media screen and (max-width:321px) {
    #my{
        font-size: .8em;
        .point_list>li{
            // text-align: start;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>