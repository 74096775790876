<template>
    <div class="pb-nav">
        <TitleHeader :title="title" />
        <div class="container">
            <p class="small mx-3 py-3">회원님의 개인정보를 안전하게 보호하고, 개인정보
            도용으로 인한 피해를 예방하기 위해 30일 이상
            비밀번호를 변경하지 않을 경우 비밀번호 변경을
            권장하고 있습니다.</p>
            <div>
                <div class="px-3">
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50">* 기존 비밀번호를 입력해주세요.</label>
                        <input class="form-control py-px-12" type="password" required placeholder="기존 비밀번호를 입력해주세요." v-model="password">
                    </div>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50">* 새로운 비밀번호를 입력해주세요.</label>
                        <input class="form-control py-px-12 mb-2" type="password" required placeholder="새 비밀번호" v-model="new_password" maxlength="20">
                        <input class="form-control py-px-12" type="password" required placeholder="새 비밀번호 확인" v-model="new_password_confirm" maxlength="20">
                    </div>
                    <p class="text-danger mb-3 small" v-if="pw_match_chk===false">
                        <span class="small">- 비밀번호가 일치하지 않습니다</span>
                    </p>
                </div>
            
                <div class="validate_box rounded border m-3 p-3">
                    <p class="text-body text-opacity-50 mb-3 small">
                        <span class="small">- 영문, 숫자를 혼합하여 6~20자로 사용 가능합니다</span>
                    </p>
                </div>
                <div class="w-100 p-3">
                    <button @click="ChangePassword()" class="btn bg-main py-px-12 w-100 text-white">비밀번호 변경하기</button>
                </div>
            </div>
        </div>
        <div class="alert alert-success d-flex align-items-center position-fixed start-50 translate-middle-x shadow bottom-0 mb-2 max-w-px-768 alert-danger"  style="width:95%" role="alert"
            v-if="showSystemAlert">
            <i class="fas fa-check me-2"></i> 
            <div>
                {{showSystemMemo}}
            </div>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
const CryptoJS = require("crypto-js");
export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title:'비밀번호 변경하기',
            error:[false,false,false,false],
            //------

            pw_match_chk: null,
            password : '',
            new_password :'',
            new_password_confirm : '',
            showSystemAlert : false,
            showSystemMemo : '',
            
        }
    },
    computed:{
    },
    methods:{
        ChangePassword(){
            const old_password =  this.password;
            const new_password = this.new_password;
            
            var reg_pw = /^.*(?=.{6,20})(?=.*[0-9])(?=.*[a-zA-Z]).*$/;

            if(old_password ==''){
                this.showSystemMemo = '기존 비밀번호를 입력해주세요.'
                this.showSystemAlert = true;
                setTimeout(() => {
                    this.showSystemMemo = '';
                    this.showSystemAlert = false;
                }, 2000);
                return false;
            }

            if(!reg_pw.test(new_password)){

                this.showSystemMemo = '비밀번호 형식이 잘못되었습니다.\n'+'(영문,숫자를 혼합하여 6~20자 이내)'
                this.showSystemAlert = true;
                setTimeout(() => {
                    this.showSystemMemo = '';
                    this.showSystemAlert = false;
                }, 2000);
                return false;
            }

            const body = {old_password,new_password};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/mypage/ChangePassword',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            
                            this.showSystemMemo = '비밀번호를 변경하였습니다.'
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                            
                            this.$store.dispatch('SETLOGOUT').then(async () => {
                                window.location.href = '/signin';
                            });

                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(async () => {
                                window.location.href = '/signin';
                            });
                        }else if(res.data.code =="100"){
                            this.showSystemMemo = "기존 비밀번호를 확인해주세요."
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        }
                    }
                }
            );
        },
        CheckPassword(){
            

            
        },
        CheckPasswordMatch(){
            const password = this.new_password;
            const password_confirm = this.new_password_confirm;

            if(password_confirm != password){
                this.pw_match_chk = false;

            }
        },
        isNumStr(value){
            let hasLetter = false;
            let hasNumber = false;
            for (let char of value) {
                if (isNaN(char * 1)) {
                hasLetter = true;
                } else {
                hasNumber = true;
                }
            }
            if (!hasLetter || !hasNumber) {
                return false;
            }else{
                return true;
            }
        }
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    },
}
</script>